import { CssBaseline } from "@mui/material";
import "./App.css";
import MainPage from "./components/MainPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#f5ebe0",
      paper: "#f5ebe0",
      // paper: CUSTOM_THEME_COLORS.backgroundPaper,
      // // active: "#262633",
      // // @ts-ignore
      // header: CUSTOM_THEME_COLORS.backgroundHeader,
    },
    primary: {
      main: "#8faa9b",
      // common: "#375bde",
    },
    secondary: {
      main: "#71493e",
    },
    // error: {
    //   main: CUSTOM_THEME_COLORS.error,
    // },
    // warning: {
    //   main: CUSTOM_THEME_COLORS.warning,
    // },
    // success: {
    //   main: CUSTOM_THEME_COLORS.success,
    // },
    // inactive: {
    //   main: "#8c8c8c",
    // },
    // text: {
    //   secondary: CUSTOM_THEME_COLORS.textSecondary,
    //   // @ts-ignore
    //   dark: CUSTOM_THEME_COLORS.textDark,
    // },
    // beta: {
    //   main: "#65afd3",
    // },
  },
  typography: {
    fontFamily: "Zen Kurenaido, sans-serif",
    h2: {
      fontSize: "44px",
      fontWeight: 400,
      lineHeight: "31.28px",
    },
    h4: {
      fontSize: "30px",
      fontWeight: 400,
      // lineHeight: "27px",
    },
    h5: {
      fontSize: "22px",
      fontWeight: 400,
    },
    h6: {
      fontSize: "18px",
      fontWeight: 400,
    },
    body1: {
      fontSize: 20,
      fontWeight: 400,
      lineHeight: "27px",
    },
    body2: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "25px",
    },
    overline: {
      fontSize: 13,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 400,
      // lineHeight: "28px",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
  },
  // shape: {
  //   borderRadius: CUSTOM_BORDER_RADIUS,
  // },
  spacing: 8,
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: 9,
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 800,
        },
        outlined: {
          // padding: 3,
          // py: 1,
          // border: 2,
          // ":hover": { border: 2 },
          // fontWeight: 600,
          // fontSize: "14px",
          // lineHeight: "19.07px"
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 12,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
          borderRadius: 0,
        },
      },
    },
    // MuiSkeleton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: CUSTOM_THEME_COLORS.backgroundPaper,
    //       borderRadius: CUSTOM_BORDER_RADIUS,
    //     },
    //   },
    // },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       borderBottom: `2px solid ${addAlpha(
    //         CUSTOM_THEME_COLORS.textSecondary,
    //         0.2
    //       )}`,
    //     },
    //   },
    // },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainPage />
    </ThemeProvider>
  );
}

export default App;
