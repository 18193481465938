import { Box, Link, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { TSearchResult } from "../library";

interface PlaceInfoPanelProps {
  place: TSearchResult;
}

const PlaceInfoPanel: React.FC<PlaceInfoPanelProps> = ({ place }) => {
  return (
    <Box style={{ padding: "0px 0px 0px 4px" }}>
      <Typography
        style={{
          maxWidth: 150,
          fontSize: 14,
          fontWeight: 600,
          margin: "0px 0px 4px 0px",
        }}
      >
        {place.name}
      </Typography>
      {!!place.fullResult.photos && (
        <Box display="flex" alignItems="center" minWidth={150} minHeight={150}>
          <img
            src={place.fullResult.photos[0].getUrl({ maxHeight: 300 })}
            alt={place.name}
            height={150}
            width={150}
            style={{
              // borderRadius: "50%",
              objectFit: "cover",
            }}
            onError={(e) => {
              // @ts-ignore
              e.target.parentNode.removeChild(e.target);
            }}
          />
        </Box>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "top",
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }}>
          <StarIcon
            style={{ fontSize: "12px", minWidth: "12px", marginBottom: "1px" }}
          />
          <Typography style={{ display: "inline", fontSize: 12, margin: 0 }}>
            {place.fullResult.rating}
          </Typography>
        </Box>
        <Link
          // href={`https://www.google.com/maps/place/?q=place_id:${place.placeId}`}
          href={`https://www.google.com/maps/search/?api=1&query=${place.fullResult.plus_code?.global_code}&query_place_id=${place.placeId}`}
          target="_blank"
          rel="noreferrer"
          style={{
            margin: "0px",
            textDecoration: "none",
            fontSize: 10,
            fontStyle: "italic",
            fontWeight: 500,
            color: "#8faa9b",
          }}
        >
          View in Maps
        </Link>
      </Box>
    </Box>
  );
};

export default PlaceInfoPanel;
