import React from "react";
import ReactDOMServer from "react-dom/server";
import { GoogleDataProvider, TLatLng, TSearchResult } from "../library";
import { Box, Button, Typography } from "@mui/material";
import PlaceInfoPanel from "./PlaceInfoPanel";

type THappyPlaceKind = "circle" | "craft" | "knit" | "coffee";

interface IRenderInfo {
  displayName: string;
  pinBackgroundColor: string;
  pinBorderColor: string;
}

const renderInfo: Record<THappyPlaceKind, IRenderInfo> = {
  circle: {
    displayName: "CIRCLE K",
    pinBackgroundColor: "#f99b2a",
    pinBorderColor: "#da291c",
  },
  craft: {
    displayName: "CRAFTING",
    pinBackgroundColor: "#8faa9b", // primary
    pinBorderColor: "#203228",
  },
  knit: {
    displayName: "KNITTING",
    pinBackgroundColor: "#71493e",
    pinBorderColor: "black",
  },
  coffee: {
    displayName: "GOOD COFFEE",
    pinBackgroundColor: "#f5ebe0",
    pinBorderColor: "#71493e", // secondary
  },
};

interface IMapTestProps {
  position: TLatLng;
}

interface IPlaceButtonProps {
  kind: THappyPlaceKind;
  place: TSearchResult | null;
  onSelect: (placeId: string) => void;
}

const provider = new GoogleDataProvider();

const PlaceButton: React.FC<IPlaceButtonProps> = ({
  kind,
  place,
  onSelect,
}) => {
  const onClick = React.useCallback(() => {
    if (place) {
      onSelect(place.placeId);
    }
  }, [place, onSelect]);
  return (
    <Box mx={1} flex="50%">
      <Button
        variant="contained"
        fullWidth={true}
        sx={{ mb: -0.5 }}
        onClick={onClick}
      >
        {renderInfo[kind].displayName}
      </Button>
      <Typography variant="caption" mt={-1}>
        {place
          ? `Closest: ${formatDistance(place.distance)} away`
          : `Closest: ?`}
      </Typography>
    </Box>
  );
};

const MapView: React.FC<IMapTestProps> = ({ position }) => {
  const model = React.useRef<PlacesModel>();
  const [closestCraft, setClosestCraft] = React.useState<TSearchResult | null>(
    null
  );
  const [closestCircle, setClosestCircle] =
    React.useState<TSearchResult | null>(null);
  const [closestCoffee, setClosestCoffee] =
    React.useState<TSearchResult | null>(null);
  const [closestKnit, setClosestKnit] = React.useState<TSearchResult | null>(
    null
  );
  const onSelect = React.useCallback((placeId: string) => {
    model.current?.onSelect(placeId);
  }, []);
  React.useEffect(() => {
    (async () => {
      const map = await provider.initMap(position);
      model.current = new PlacesModel(position, map);
      const [artSupply, circle, coffee, knit] = await Promise.all([
        provider.getArtSupplyStores(),
        provider.getCircleStations(),
        provider.getCoffeeShops(),
        provider.getKnitShops(),
      ]);
      setClosestCraft(artSupply.closest);
      setClosestCircle(circle.closest);
      setClosestCoffee(coffee.closest);
      setClosestKnit(knit.closest);
      const allResults: Array<IPlaceModel> = [];
      artSupply.nearby.forEach((res) =>
        allResults.push({
          kind: "craft",
          place: res,
        })
      );
      circle.nearby.forEach((res) =>
        allResults.push({
          kind: "circle",
          place: res,
        })
      );
      coffee.nearby.forEach((res) =>
        allResults.push({
          kind: "coffee",
          place: res,
        })
      );
      knit.nearby.forEach((res) =>
        allResults.push({
          kind: "knit",
          place: res,
        })
      );
      allResults.sort((a, b) => b.place.pos.lat - a.place.pos.lat);
      model.current?.addModels(allResults);
    })();
  }, [position]);
  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" mb={1} mx={-1}>
        <PlaceButton kind="coffee" place={closestCoffee} onSelect={onSelect} />
        <PlaceButton kind="craft" place={closestCraft} onSelect={onSelect} />
      </Box>
      <Box display="flex" mb={2} mx={-1}>
        <PlaceButton kind="knit" place={closestKnit} onSelect={onSelect} />
        <PlaceButton kind="circle" place={closestCircle} onSelect={onSelect} />
      </Box>
      <Box
        id="maproot"
        flexGrow={1}
        height="100%"
        sx={{
          borderRadius: 3,
          boxShadow: "2px 2px 8px 1px #71493eb0",
        }}
      ></Box>
    </Box>
  );
};

function formatDistance(distance: number): string {
  if (distance < 100) {
    return `${Math.floor(distance)}m`;
  }
  if (distance < 900) {
    return `${Math.floor(distance / 100)}00m`;
  }
  if (distance < 10000) {
    return `${Math.floor(distance / 100) / 10}km`;
  }
  return `${Math.floor(distance / 1000)}km`;
}

interface IPlaceModel {
  place: TSearchResult;
  kind: THappyPlaceKind;
}

interface IMarkerModel extends IPlaceModel {
  pinView: any; // sorry
  markerView: any;
}

// last mile todo
// * manually add Royal Artisan to results
// * filter by open now

class PlacesModel {
  private readonly position: TLatLng;
  private readonly map: google.maps.Map;
  private readonly userMarker: google.maps.Marker;
  private readonly infoWindow = new google.maps.InfoWindow();
  private readonly placeMarkers: Record<string, IMarkerModel> = {};
  private selected: string | null = null;

  constructor(position: TLatLng, map: google.maps.Map) {
    this.position = position;
    this.map = map;
    this.userMarker = new google.maps.Marker({
      position,
      map,
      icon: {
        url: "./favicon.ico",
        scaledSize: new google.maps.Size(30, 30),
      },
      opacity: 0.9,
    });
    this.userMarker.addListener("click", () => {
      if (this.selected) {
        this.deselect(this.selected);
      }
    });
    google.maps.event.addListener(this.infoWindow, "closeclick", () => {
      console.log("closed");
      if (this.selected) {
        this.deselect(this.selected);
      }
    });
  }

  public onSelect(placeId: string) {
    if (!this.placeMarkers[placeId]) {
      console.warn(`Place is not tracked`);
      return;
    }
    if (this.selected) {
      this.deselect(this.selected);
    }
    this.select(this.placeMarkers[placeId]);
  }

  private deselect(placeId: string) {
    const model = this.placeMarkers[placeId];
    if (!model) {
      return;
    }
    this.selected = null;
    this.infoWindow.close();
    model.pinView.scale = 0.8;
  }

  private select(model: IMarkerModel) {
    console.log(`Selecting ${model.place.name}`);
    this.selected = model.place.placeId;
    model.pinView.scale = 1.1;
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.position);
    bounds.extend(model.place.pos);
    const infoWindowContent = ReactDOMServer.renderToString(
      <PlaceInfoPanel place={model.place} />
    );
    this.infoWindow.setOptions({
      content: infoWindowContent,
    });
    this.map.fitBounds(bounds, {
      top: 320,
      right: 150,
      left: 120,
    });
    this.infoWindow.open(this.map, model.markerView);
  }

  public addModels(models: Array<IPlaceModel>) {
    models.forEach((model) => this.addModel(model));
  }

  private addModel(model: IPlaceModel) {
    // @ts-ignore
    const pinView = new google.maps.marker.PinView({
      background: renderInfo[model.kind].pinBackgroundColor,
      borderColor: renderInfo[model.kind].pinBorderColor,
      glyphColor: renderInfo[model.kind].pinBorderColor,
      scale: 0.8,
    });
    // @ts-ignore
    const markerView = new google.maps.marker.AdvancedMarkerView({
      map: this.map,
      position: model.place.pos,
      content: pinView.element,
    });

    // @ts-ignore
    markerView.addListener("click", ({ domEvent, latLng }) => {
      this.onSelect(model.place.placeId);
    });

    this.placeMarkers[model.place.placeId] = {
      ...model,
      pinView,
      markerView,
    };
  }
}

export default MapView;
