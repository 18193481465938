import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { TLatLng } from "../library";

export interface IIntroProps {
  onPositionReceived: (pos: TLatLng) => void;
}

const Intro: React.FC<IIntroProps> = ({ onPositionReceived }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  return (
    <Box textAlign="center">
      <Typography variant="body2">
        Hi there! Before we start, I need to warn you that this page requires
        location services to be on. It's likely that you (reasonably) have them
        disabled globally. Please turn them on for this brief moment!
      </Typography>
      <Typography variant="body2" mt={1}>
        {loading ? `Thanks! ❤️ This may take a few secs...` : `Shall we?`}
      </Typography>
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        disabled={loading}
        onClick={async () => {
          // kekl;
          console.log("clicked");
          setLoading(true);
          setError(false);
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              console.log("got positon", pos);
              setLoading(false);
              // onPositionReceived({
              //   lat: 52.163988,
              //   lng: 21.092622,
              // });
              onPositionReceived({
                lat: pos.coords.latitude,
                lng: pos.coords.longitude,
              });
            },
            (err) => {
              console.error(err);
              setError(true);
            }
          );
        }}
      >
        {loading ? `TEMU GO` : `KOMU LET'S`}
      </Button>
      {error && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          <Typography variant="caption">
            Ouch, this didn't quite work. Try going to Settings, then Privacy &
            Safety, then Chrome/Safari and turn some knobs there. After that,
            refresh the page.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            minWidth={180}
            minHeight={180}
            mt={3}
          >
            <img
              src={"./pusheen.png"}
              alt={"Sad pusheen"}
              height={180}
              width={180}
              onError={(e) => {
                // @ts-ignore
                e.target.parentNode.removeChild(e.target);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Intro;
