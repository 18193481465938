import { Loader } from "@googlemaps/js-api-loader";

export type TLatLng = {
  lat: number;
  lng: number;
};

export type TSearchResult = {
  placeId: string;
  pos: TLatLng;
  distance: number;
  name: string;
  fullResult: google.maps.places.PlaceResult;
};

export type TFullResults = {
  nearby: Array<TSearchResult>;
  closest: TSearchResult | null;
};

export interface IDataProvider {
  initMap: (userPos: TLatLng) => Promise<google.maps.Map>;
  getArtSupplyStores: () => Promise<TFullResults>;
  getCircleStations: () => Promise<TFullResults>;
  getCoffeeShops: () => Promise<TFullResults>;
  getKnitShops: () => Promise<TFullResults>;
}

const loader = new Loader({
  apiKey: "AIzaSyCCnW9KkwKrp4kW9tyoxFDc9lQp5mpSssA",
  version: "beta",
});

export class GoogleDataProvider implements IDataProvider {
  private mapsLibrary: google.maps.MapsLibrary | null = null;
  private placesLibrary: google.maps.PlacesLibrary | null = null;
  private geometryLibrary: google.maps.GeometryLibrary | null = null;
  private markerLibrary: google.maps.MarkerLibrary | null = null;
  private userPos: TLatLng | null = null;
  private map: google.maps.Map | null = null;
  private allLoaded = false;
  private readonly awaiters: Array<() => void> = [];

  constructor() {
    this.loadEverything();
  }

  public async initMap(userPos: TLatLng): Promise<google.maps.Map> {
    this.userPos = userPos;
    await this.awaitLibsLoaded();
    const { Map } = this.mapsLibrary!;
    const mapElement = await this.findMapElement();
    this.map = new Map(mapElement, {
      center: userPos,
      zoom: 12,
      mapId: "DEMO_MAP_ID",
    });
    return this.map;
  }

  public async getArtSupplyStores(): Promise<TFullResults> {
    await this.awaitLibsLoaded();
    const { PlacesService } = this.placesLibrary!;
    const service = new PlacesService(this.map!);
    const request: google.maps.places.PlaceSearchRequest = {
      location: this.userPos!,
      radius: 2000,
      keyword: "art supply shop",
      type: "store",
    };
    const results = await new Promise<google.maps.places.PlaceResult[] | null>(
      (resolve) => {
        service.nearbySearch(request, resolve);
      }
    );
    const nearby: Array<TSearchResult> = (results || [])
      .filter(
        (place) =>
          place.business_status ===
          google.maps.places.BusinessStatus.OPERATIONAL
      )
      .filter((place) => place.rating! >= 4)
      .filter((place) => place.geometry?.location)
      .filter((place) => place.name)
      .filter((place) => place.place_id)
      .map((place) => {
        const placePos = {
          lat: place.geometry?.location?.lat()!,
          lng: place.geometry?.location?.lng()!,
        };
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          placePos,
          this.userPos!
        );
        return {
          pos: placePos,
          placeId: place.place_id!,
          name: place.name!,
          distance,
          fullResult: place,
        };
      })
      .sort((a, b) => a.distance - b.distance);

    console.log("ART SUPPLY", nearby);

    return {
      nearby,
      closest: nearby.length ? nearby[0] : null,
    };
  }

  public async getCoffeeShops(): Promise<TFullResults> {
    await this.awaitLibsLoaded();
    const { PlacesService } = this.placesLibrary!;
    const service = new PlacesService(this.map!);
    const request: google.maps.places.PlaceSearchRequest = {
      location: this.userPos!,
      radius: 2000,
      keyword: "coffee shop",
    };
    const results = await new Promise<google.maps.places.PlaceResult[] | null>(
      (resolve) => {
        service.nearbySearch(request, resolve);
      }
    );
    const extraRequest: google.maps.places.PlaceSearchRequest = {
      location: this.userPos!,
      radius: 2000,
      keyword: "royal artisan",
      type: "bakery",
    };
    const moreResults = await new Promise<
      google.maps.places.PlaceResult[] | null
    >((resolve) => {
      service.nearbySearch(extraRequest, resolve);
    });
    const nearby: Array<TSearchResult> = (results || [])
      .concat(moreResults || [])
      .filter(
        (place) =>
          place.business_status ===
          google.maps.places.BusinessStatus.OPERATIONAL
      )
      .filter((place) => place.rating! >= 4)
      .filter((place) => place.geometry?.location)
      .filter((place) => place.name)
      .filter((place) => place.place_id)
      .map((place) => {
        const placePos = {
          lat: place.geometry?.location?.lat()!,
          lng: place.geometry?.location?.lng()!,
        };
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          placePos,
          this.userPos!
        );
        return {
          pos: placePos,
          placeId: place.place_id!,
          name: place.name!,
          distance,
          fullResult: place,
        };
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 8);

    console.log("COFFEE", nearby);

    return {
      nearby,
      closest: nearby.length ? nearby[0] : null,
    };
  }

  public async getKnitShops(): Promise<TFullResults> {
    await this.awaitLibsLoaded();
    const { PlacesService } = this.placesLibrary!;
    const service = new PlacesService(this.map!);
    const request: google.maps.places.PlaceSearchRequest = {
      location: this.userPos!,
      radius: 2000,
      keyword: "knit shop",
      type: "store",
    };
    const results = await new Promise<google.maps.places.PlaceResult[] | null>(
      (resolve) => {
        service.nearbySearch(request, resolve);
      }
    );
    const nearby: Array<TSearchResult> = (results || [])
      .filter(
        (place) =>
          place.business_status ===
          google.maps.places.BusinessStatus.OPERATIONAL
      )
      .filter((place) => place.rating! >= 4)
      .filter((place) => place.geometry?.location)
      .filter((place) => place.name)
      .filter((place) => place.place_id)
      .map((place) => {
        const placePos = {
          lat: place.geometry?.location?.lat()!,
          lng: place.geometry?.location?.lng()!,
        };
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          placePos,
          this.userPos!
        );
        return {
          pos: placePos,
          placeId: place.place_id!,
          name: place.name!,
          distance,
          fullResult: place,
        };
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 8);

    console.log("COFFEE", nearby);

    return {
      nearby,
      closest: nearby.length ? nearby[0] : null,
    };
  }

  public async getCircleStations(): Promise<TFullResults> {
    await this.awaitLibsLoaded();
    const { PlacesService } = this.placesLibrary!;
    const service = new PlacesService(this.map!);
    const request: google.maps.places.PlaceSearchRequest = {
      location: this.userPos!,
      radius: 10000,
      keyword: "circle k",
      type: "gas_station",
    };
    const results = await new Promise<google.maps.places.PlaceResult[] | null>(
      (resolve) => {
        service.nearbySearch(request, resolve);
      }
    );
    let nearby: Array<TSearchResult> = (results || [])
      .filter(
        (place) =>
          place.business_status ===
          google.maps.places.BusinessStatus.OPERATIONAL
      )
      .filter((place) => place.geometry?.location)
      .filter((place) => place.name)
      .filter((place) => !place.name!.toLowerCase().includes("express"))
      .filter((place) => place.place_id)
      .map((place) => {
        const placePos = {
          lat: place.geometry?.location?.lat()!,
          lng: place.geometry?.location?.lng()!,
        };
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          placePos,
          this.userPos!
        );
        return {
          pos: placePos,
          placeId: place.place_id!,
          name: place.name!,
          distance,
          fullResult: place,
        };
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 8);

    console.log("CIRCLE K", nearby);

    if (!nearby.length) {
      const textRequest: google.maps.places.TextSearchRequest = {
        location: this.userPos!,
        radius: 10000,
        query: "circle k",
        type: "gas_station",
      };
      const results = await new Promise<
        google.maps.places.PlaceResult[] | null
      >((resolve) => {
        service.textSearch(textRequest, resolve);
      });
      nearby = (results || [])
        .filter(
          (place) =>
            place.business_status ===
            google.maps.places.BusinessStatus.OPERATIONAL
        )
        .filter((place) => place.geometry?.location)
        .filter((place) => place.name)
        .filter((place) => !place.name!.toLowerCase().includes("express"))
        .filter((place) => place.place_id)
        .map((place) => {
          const placePos = {
            lat: place.geometry?.location?.lat()!,
            lng: place.geometry?.location?.lng()!,
          };
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              placePos,
              this.userPos!
            );
          return {
            pos: placePos,
            placeId: place.place_id!,
            name: place.name!,
            distance,
            fullResult: place,
          };
        })
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 5);
    }

    return {
      nearby,
      closest: nearby.length ? nearby[0] : null,
    };
  }

  private awaitLibsLoaded() {
    return new Promise<void>((resolve) => {
      if (this.allLoaded) {
        resolve();
      }
      this.awaiters.push(resolve);
    });
  }

  private async loadEverything() {
    await loader.load();
    await Promise.all([
      this.loadMapsLib(),
      this.loadPlacesLib(),
      this.loadGeoLib(),
      this.loadMarkerLib(),
    ]);
    console.log("libs loaded");
    this.allLoaded = true;
    this.awaiters.forEach((res) => res());
  }

  private async loadMapsLib() {
    if (!this.mapsLibrary) {
      this.mapsLibrary = (await google.maps.importLibrary(
        "maps"
      )) as google.maps.MapsLibrary;
    }
  }

  private async loadPlacesLib() {
    if (!this.placesLibrary) {
      this.placesLibrary = (await google.maps.importLibrary(
        "places"
      )) as google.maps.PlacesLibrary;
    }
  }

  private async loadGeoLib() {
    if (!this.geometryLibrary) {
      this.geometryLibrary = (await google.maps.importLibrary(
        "geometry"
      )) as google.maps.GeometryLibrary;
    }
  }

  private async loadMarkerLib() {
    if (!this.markerLibrary) {
      this.markerLibrary = (await google.maps.importLibrary(
        "marker"
      )) as google.maps.MarkerLibrary;
    }
  }

  private async findMapElement(): Promise<HTMLElement> {
    while (true) {
      const maybeMapElement = document.getElementById("maproot");
      if (maybeMapElement) {
        return maybeMapElement;
      }
      console.log("Waiting for map element to show up");
      await new Promise((r) => setTimeout(r, 500));
    }
  }
}
