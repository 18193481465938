import React from "react";
import Intro from "./Intro";
import MapView from "./MapView";
import { Box, Container, Typography } from "@mui/material";
import { TLatLng } from "../library";

export const FIXED_POS = {
  lat: 51.5006366,
  lng: -0.130359,
};

const MainPage: React.FC = () => {
  const [position, setPosition] = React.useState<TLatLng | null>(null);
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Container
        maxWidth={"xs"}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 6,
          mb: 4,
          flexGrow: 1,
          px: 4,
        }}
      >
        <Typography textAlign="center" variant="h4" mt={-4} mb={2}>
          Happiness Finder
        </Typography>
        {position ? (
          <MapView position={position} />
        ) : (
          <Intro onPositionReceived={setPosition} />
        )}
      </Container>
      <Typography textAlign="center" fontSize={12} mb={1}>
        Made with ❤️ by 🦔
      </Typography>
    </Box>
  );
};

export default MainPage;
